//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    userRatingCount: {
      type: Number,
      required: true
    },
    reviewsUri: {
      type: String,
      required: true
    },
    rating: {
      type: Number,
      required: true
    }
  },
  computed: {
    totalStarFloor () {
      return Math.floor(this.rating)
    },
    totalStarCeil () {
      return Math.ceil(this.rating)
    },
    partialStarPercentage () {
      return this.rating > this.totalStarFloor ? (this.rating - this.totalStarFloor) * 100 : 0
    }
  }
}
