//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    colored: {
      type: Boolean,
      default: false
    },
    partiallyColored: {
      type: Boolean,
      default: false
    },
    partiallyColoredPercentage: {
      type: Number,
      default: 0
    }
  }
}
