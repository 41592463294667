export default function ({ $config, $axios, $cookies, $country }, inject) {
  const client = $axios.create({
    baseURL: $config.internalApiUrl
  })
  client.defaults.timeout = 30000

  client.onRequest((config) => {
    config.headers.common['Accept-Language'] = $country.current.language
    config.headers.common['X-Language'] = $country.current.language
    config.headers.common.Country = $country.current.countryCode
  })

  /**
   * Get request configuration with authentication
   * headers injected
   * @param {*} config
   */
  const configWithAuth = (config = {}) => {
    if (!process.client) { return config }

    const token = $cookies.get('api_token')
    const account = $cookies.get('active_account')
    if (token && account) {
      if (!config.headers) {
        config.headers = {}
      }

      config.headers.Authorization = `Bearer ${token}`
      config.headers.ActiveAccount = account
    }

    return config
  }

  inject('api', {
    v2: {
      register (data) {
        return client.post('/v2/register', data)
      },
      templateRequest (data) {
        return client.post('/v2/system-integrations/intercom/template-request', data)
      },
      getRating () {
        return client.get('/v2/utils/rating').then(({ data }) => data.data)
      }
    },
    getAlgoliaSecuredApiKeys () {
      return client.get('/algolia/securedApiKeys')
    },
    getTemplate (id, previewUrl = null, params = {}) {
      if (previewUrl) {
        return $axios.get(previewUrl)
      }

      return client.get('/templates/' + id, {
        params
      })
    },
    getRelatedTemplatesForTemplate (id) {
      return client.get(`/templates/${id}/related`)
    },
    getTemplates (limit = 25, params = {}) {
      return client.get('/templates', {
        params: Object.assign({
          country: $country.current.code,
          sort: 'name',
          limit
        }, params)
      })
    },
    getTemplatesByCategory (categoryId, limit = 25, params = {}) {
      return client.get(`/categories/${categoryId}/templates`, {
        params: Object.assign({
          per_page: limit,
        }, params)
      })
    },
    getCategories () {
      return client.get('/categories', {
        params: {
          limit: 999
        }
      })
    },
    getCategory (id) {
      return client.get(`/categories/${id}`)
    },
    getArticleCategories () {
      return client.get('/article-categories', {
        params: {
          limit: 999
        }
      })
    },
    getArticleCategory (slug) {
      return client.get('/article-categories/' + slug)
    },
    getArticles (categoryId, limit = 25) {
      return client.get('/articles', { params: { categoryId, limit } })
    },
    getArticle (slug, previewUrl = null) {
      if (previewUrl) {
        return $axios.get(previewUrl)
      }

      return client.get('/articles/' + slug)
    },
    getRelatedArticles (slug, limit = 25) {
      return client.get(`/articles/${slug}/related`, { params: { limit } })
    },
    getRelatedTemplatesForArticle (slug, limit = 25) {
      return client.get(`/articles/${slug}/related-templates`, { params: { limit } })
    },
    createContract (templateId) {
      return client.post(`/templates/${templateId}/start`, {}, configWithAuth())
    },
    getProfile () {
      return client.get('/me', configWithAuth())
    },
    getWorkspace () {
      const account = $cookies.get('active_account')
      if (account === 'personal') {
        throw new Error('No active workspace')
      }

      return client.get(`/me/companies/${account}`, configWithAuth())
    },
    getPriceList (name = 'current') {
      return client.get(`/v2/price-lists/${name}?include=addons.prices`)
    },
    postContactForm (data) {
      return client.post('/v2/system-integrations/intercom/contact-request', data)
    },
  })
}
